import React from "react";

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery";
import { StaticImage } from "gatsby-plugin-image";
import { pagesLinks } from "../../../pages-data/_V2/common/links";

import styles from "./styles.module.scss";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 533,
					tabName: "Web-kassa",
					desktopTitle: "Надёжная онлайн-касса для предпринимателей",
					tabletTitle: "Надёжная онлайн-касса \nдля предпринимателей",
					desktopContent: (
						<p>
							Web-kassa работает на&nbsp;любом устройстве, это позволяет экономить на&nbsp;оборудовании. Интеграции
							позволяют фискализировать платёжные терминалы и&nbsp;онлайн <br />
							продажи
						</p>
					),
					tabletContent: (
						<p>
							Web-kassa работает на&nbsp;любом устройстве, это позволяет экономить на&nbsp;оборудовании. Интеграции
							позволяют фискализировать платёжные терминалы и&nbsp;онлайн продажи
						</p>
					),
					mobileContent: (
						<p>
							Web-kassa работает на&nbsp;любом устройстве, это позволяет экономить на&nbsp;оборудовании. Интеграции
							позволяют фискализировать платёжные терминалы и&nbsp;онлайн продажи
						</p>
					),
					mediaContent: (
						<StaticImage
							className={styles.image}
							src="./assets/delivery-list-1_kz.webp"
							alt={"деливери клаб"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 535,
					tabName: "Glovo",
					desktopTitle: "Получай заказы от лояльных пользователей",
					tabletTitle: "Получай заказы от лояльных пользователей",
					mobileTitle: "Получай \nзаказы \nот лояльных \nпользователей",
					desktopContent: (
						<p>
							Интеграция с&nbsp;Glovo помогает рестораторам расширить свою&nbsp;клиентскую базу и&nbsp;увеличить заказы,
							предоставляя клиентам удобную опцию доставки еды.
						</p>
					),
					tabletContent: (
						<p>
							Интеграция с&nbsp;Glovo помогает рестораторам расширить свою клиентскую базу <br />
							и&nbsp;увеличить заказы, предоставляя клиентам удобную опцию доставки еды.
						</p>
					),
					mobileContent: (
						<p>
							Интеграция с&nbsp;Glovo помогает рестораторам расширить свою&nbsp;клиентскую базу и&nbsp;увеличить заказы,
							предоставляя&nbsp;клиентам удобную&nbsp;опцию доставки еды.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={styles.image}
							src="./assets/delivery-list-2_kz.webp"
							alt={"яндекс еда"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 523,
					tabName: "Wolt",
					desktopTitle: "Привлекай новых гостей в свой ресторан",
					tabletTitle: "Привлекай новых гостей \nв свой ресторан",
					mobileTitle: "Привлекай новых гостей в свой ресторан",
					desktopContent: (
						<p>
							Интеграция с&nbsp;WOLT позволяет расширить своё онлайн-присутствие и&nbsp;привлекать новых клиентов через
							популярную&nbsp;платформу для доставки еды.
						</p>
					),
					tabletContent: (
						<p>
							Интеграция с&nbsp;WOLT позволяет расширить своё онлайн-присутствие и&nbsp;привлекать новых клиентов
							через&nbsp;популярную платформу для доставки еды.
						</p>
					),
					mobileContent: (
						<p>
							Интеграция с&nbsp;WOLT позволяет расширить своё онлайн-присутствие и&nbsp;привлекать новых&nbsp;клиентов
							через
							популярную&nbsp;платформу для&nbsp;доставки еды.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={styles.image}
								src="./assets/delivery-list-3_kz.webp"
								alt={"яндекс доставка"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 523,
					tabName: "Chocofood",
					desktopTitle: "Расширяй горизонты своего бизнеса",
					tabletTitle: "Расширяй горизонты своего бизнеса",
					mobileTitle: "Расширяй \nгоризонты \nсвоего бизнеса",
					desktopContent: (
						<p>
							Интеграция увеличивает видимость в онлайн <br />
							и доступность ресторана для клиентов. Chocofood <br />
							помогает быстро и&nbsp;легко расширить бизнес через <br />
							онлайн-заказы еды.
						</p>
					),
					tabletContent: (
						<p>
							Интеграция увеличивает видимость в&nbsp;онлайн и&nbsp;доступность ресторана для клиентов. <br />
							Chocofood помогает быстро и&nbsp;легко расширить бизнес через онлайн-заказы еды.
						</p>
					),
					mobileContent: (
						<p>
							Интеграция увеличивает видимость в&nbsp;онлайн и&nbsp;доступность ресторана для&nbsp;клиентов. Chocofood
							помогает&nbsp;быстро и легко расширить бизнес через <br />
							онлайн-заказы еды.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={styles.image}
								src="./assets/delivery-list-4_kz.webp"
								alt={"яндекс доставка"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "kz-KZ") {
		return {
			features: [
				{
					desktopContentWidth: 533,
					tabName: "Web-kassa",
					desktopTitle: "Кәсіпкерлерге арналған сенімді онлайн-касса",
					tabletTitle: "Кәсіпкерлерге арналған\nсенімді онлайн-касса",
					desktopContent: (
						<p>
							Webkassa кез келген құрылғыда жұмыс істейді, бұл жабдықты үнемдеуге мүмкіндік береді.
							Интеграциялар төлем  терминалдарын және онлайн сатуды фискалдауға мүмкіндік береді.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={styles.image}
							src="./assets/delivery-list-1_kz.webp"
							alt={"деливери клаб"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 535,
					tabName: "Glovo",
					desktopTitle: "Адал пайдаланушылардан тапсырыстарды алыңыз",
					desktopContent: (
						<p>
							Glovo-мен интеграция рестораторларға өз клиенттік қорын кеңейтулеріне және клиенттерге
							тамақ жеткізудің ыңғайлы опциясын ұсына отырып тапсырыстарды ұлғайтуларына көмектеседі.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={styles.image}
							src="./assets/delivery-list-2_kz.webp"
							alt={"яндекс еда"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 523,
					tabName: "Wolt",
					desktopTitle: "Өз мейрамханаңызға жаңа қонақтарды тартыңыз",
					desktopContent: (
						<p>
							WOLT-пен интеграция өз онлайн-қатысуын кеңейтуге және тамақты жеткізуге арналған танымал
							платформа арқылы жаңа клиенттерді тартуға мүмкіндік береді.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={styles.image}
								src="./assets/delivery-list-3_kz.webp"
								alt={"яндекс доставка"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 523,
					tabName: "Chocofood",
					desktopTitle: "Бизнесіңіздің шектерін кеңейтіңіз",
					desktopContent: (
						<p>
							Интеграция онлайнда көрінгендікті және мейрамхананың клиенттер үшін қолжетімділігін ұлғайтады.
							Chocofood тамақты онлайн-тапсырыстары арқылы бизнесті тез әрі оңай кеңейтуге көмектеседі.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={styles.image}
								src="./assets/delivery-list-4_kz.webp"
								alt={"яндекс доставка"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	if (locale === "ru-BY") {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "Яндекс Еда",
					desktopTitle: "Интеграция \nна любой вкус",
					tabletTitle: "Интеграция на любой вкус",
					mobileTitle: "Интеграция \nна любой вкус",
					desktopContent: (
						<p>
							Прием заказов, оформленных через приложение и&nbsp;сайт агрегатора, на&nbsp;кассовом терминале, доставка
							заказов курьерами сервиса или с&nbsp;помощью своих сотрудников, автоматическое обновление меню при
							изменениях <br />
							в&nbsp;бэк-офисе.
						</p>
					),
					tabletContent: (
						<p>
							Прием заказов, оформленных через приложение и&nbsp;сайт агрегатора, на&nbsp;кассовом терминале, доставка
							заказов курьерами сервиса или с&nbsp;помощью своих сотрудников, автоматическое обновление меню при
							изменениях в&nbsp;бэк-офисе.
						</p>
					),
					mobileContent: (
						<p>
							Прием заказов, оформленных через приложение и&nbsp;сайт агрегатора, на&nbsp;кассовом терминале, доставка
							заказов курьерами сервиса или с помощью&nbsp;своих сотрудников, автоматическое обновление меню при
							изменениях в&nbsp;бэк-офисе.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={styles.image}
							src="./assets/delivery-list-2.png"
							alt={"яндекс еда"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 500,
					tabName: "Яндекс Доставка",
					desktopTitle: "Партнёр \nпо логистике",
					tabletTitle: "Партнёр по логистике",
					mobileTitle: "Партнёр \nпо логистике",
					desktopContent: (
						<p>
							Незаменимый партнёр для доставки заказов в&nbsp;пиковые часы. Среднее время на&nbsp;поиск
							курьера&nbsp;&mdash; 2&nbsp;минуты, время ожидания курьера&nbsp;&mdash; 10&nbsp;минут. Управление напрямую
							с&nbsp;кассового терминала.
						</p>
					),
					tabletContent: (
						<p>
							Незаменимый партнёр для доставки заказов в&nbsp;пиковые часы. Среднее время на&nbsp;поиск
							курьера&nbsp;&mdash; 2&nbsp;минуты, время ожидания курьера&nbsp;&mdash; 10&nbsp;минут. Управление напрямую
							с&nbsp;кассового терминала.
						</p>
					),
					mobileContent: (
						<p>
							Незаменимый партнёр для доставки заказов в&nbsp;пиковые часы. Среднее время на&nbsp;поиск
							курьера &mdash;&nbsp;2&nbsp;минуты, время ожидания курьера&nbsp;&mdash; 10&nbsp;минут. Управление напрямую
							с&nbsp;кассового терминала.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={styles.image}
								src="./assets/delivery-list-3.png"
								alt={"яндекс доставка"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		};
	}

	return {
		features: [
			{
				desktopContentWidth: 545,
				tabName: "Деливери",
				desktopTitle: "Добро пожаловать в клуб",
				desktopContent: (
					<p>
						Интеграция программы для автоматизации доставки еды с&nbsp;агрегатором открывает доступ к&nbsp;новой
						аудитории. Можно привлечь гостей из&nbsp;других районов города, куда неудобно отвозить заказы курьерам кафе.
						<br />
						<br />
						В&nbsp;заведении нет своей службы доставки или не&nbsp;хватает
						собственных курьеров&nbsp;&mdash; не&nbsp;проблема. Выбирай сам, чьи
						курьеры отвезут заказ.
						<br />
						<br />
						Информация обо всех акциях и&nbsp;скидках транслируется пользователям агрегатора.
					</p>
				),
				detailLink: pagesLinks.deliveryClub,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/delivery-list-1_new.png"
						alt={"деливери клаб"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				)
			},
			{
				desktopContentWidth: 545,
				tabName: "Яндекс Еда",
				desktopTitle: "Интеграция на любой вкус",
				desktopContent: (
					<p>
						Приём заказов, оформленных через приложение и&nbsp;сайт агрегатора или на&nbsp;кассовом терминале.
						< br/>
						Меню автоматически обновляется при изменениях в&nbsp;бэк-офисе&nbsp;&mdash; в&nbsp;заказ
						не&nbsp;попадёт блюдо из&nbsp;стоп-листа, зато быстро попадут новинки. Если в&nbsp;заведении
						закончились продукты для десерта, он&nbsp;сразу попадёт в&nbsp;стоп-лист, а&nbsp;значит, клиент
						не&nbsp;успеет оставить заявку.
					</p>
				),
				detailLink: pagesLinks.yandex,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/delivery-list-2.png"
						alt={"яндекс еда"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				)
			},
			{
				desktopContentWidth: 545,
				tabName: "Яндекс Доставка",
				desktopTitle: "Партнёр по логистике",
				desktopContent: (
					<p>
						Незаменимый партнёр для помощи с доставкой заказов в&nbsp;пиковые часы. Среднее
						время на&nbsp;поиск курьера&nbsp;&mdash; 2&nbsp;минуты, время ожидания
						курьера&nbsp;&mdash; 10&nbsp;минут. Управление напрямую
						с&nbsp;кассового терминала.
					</p>
				),
				detailLink: pagesLinks.yandex,
				mediaContent: (
					<>
						<StaticImage
							className={styles.image}
							src="./assets/delivery-list-3.png"
							alt={"яндекс доставка"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				)
			},
			{
				desktopContentWidth: 545,
				tabName: "Купер",
				desktopTitle: "Доставка из магазинов и ресторанов",
				desktopContent: (
					<p>
						Крупный федеральный сервис доставки, которому доверяют миллионы клиентов. Принимай заказы
						через онлайн-витрину — все они отобразятся на кассовом терминале.
					</p>
				),
				detailLink: pagesLinks.yandex,
				mediaContent: (
					<>
						<StaticImage
							className={styles.image}
							src="./assets/delivery-list-kuper.png"
							alt={"логотип купер"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				)
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	};
};
